<template>
  <page :styleWrap="{background: '#fff'}">
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="topInfo" v-if='info'>
        <div class="logo margin_auto">
          <img style="width: 100%; height: 100%" :src="info.iconUrl" alt="">
        </div>
        <p class="beta">{{info.version}}</p>
        <div class="aboutUs">
          {{info.desc}}
        </div>
        <!-- <div class='infoItemPanel'>
          <info-item title='隐私政策' :showArrow="true"></info-item>
          <info-item title='用户服务协议' :showArrow="true"></info-item>
          <info-item title='特别声明' :showArrow="true"></info-item>
          <info-item title='联系我们' :showArrow="true"></info-item>
        </div> -->
      </div>

      <div class="copyright">
        Copyright ©2020-2022 杭州行胜于言科技有限公司版权所有
      </div>
    </div>
  </page>
</template>

<script>
// import infoItem from './components/personItem.vue'

export default {
  components: {
    // 'info-item': infoItem
  },
  data: ()=> ({
    info: null
  }),
  mounted() {
    this.info = this.$global.getItem('appConfig').aboutUsIcon

  }
}
</script>

<style lang="less" scoped>
.topInfo {
  min-height: 76vh;
}
.logo {
  width: 110px;
  height: 110px;
  margin-top: 30px;
  border-radius: 4px;
}
.beta {
  text-align: center;
  font-size: 14px;
  color: #9B9B9B;
}

.aboutUs {
  font-size: 14px;
  color: #2D2D2D;
  line-height: 20px;
  text-indent: 28px;
  margin: 15px;
  margin-bottom: 50px;
}
.infoItemPanel {
  padding-left: 15px;
}

.copyright {
  font-size: 12px;
  color: #9B9B9B;
  text-align: center;
  margin: 40px 0;
}
</style>